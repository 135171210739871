const flags = {
    // TODO replace colour codes with somewhat of an official source instead of screengrab
    "lesbian": {
        "l": "#D72C00",
        "e": "#F27326",
        "s": "#FE9858",
        "b": "#FEFEFC",
        "i": "#D263A7",
        "a": "#B75592",
        "n": "#A50162"
    }
}